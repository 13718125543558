import { getApiUrl } from "sutro-common";
import { getApiHostName, getPreviewHostName } from "sutro-common/hostnames";

const API_HOSTNAME = getApiHostName(import.meta.env.SUTRO_ENV);
const PREVIEW_HOSTNAME = getPreviewHostName(import.meta.env.SUTRO_ENV);

/**
 * If you want to be able to access an environment variable in the client, you need to add it to the list in `vite.config.ts`.
 */

export type AppConfig = {
  apiRoot: string;
  analyticsApiRoot: `https://${string}`;
  previewUrl: `https://${string}`;
  cookieDomain: string;
  shouldShowAppImmediately: boolean;
  isDev: boolean;
  isStaging: boolean;
  isProdTest: boolean;
  sentryDsn: string;
  otelServiceName: string;
  otelEndpoint: string;
  intercomAppId: string;
  posthogKey: string;
  posthogHost: string;
  stripePublicKey: string;
};

const APP_CONFIG: AppConfig = {
  apiRoot: getApiUrl(`https://${API_HOSTNAME}`),
  analyticsApiRoot: `https://${API_HOSTNAME}`,
  previewUrl: `https://${PREVIEW_HOSTNAME}`,
  cookieDomain: "withsutro.com",
  shouldShowAppImmediately: import.meta.env.NODE_ENV !== "production",
  isDev: import.meta.env.NODE_ENV !== "production",
  isStaging: import.meta.env.SUTRO_ENV === "staging",
  isProdTest: import.meta.env.PROD_TEST !== undefined,
  sentryDsn: import.meta.env.SUTRO_SENTRY_DSN,
  otelServiceName: import.meta.env.STUDIO_OTEL_SERVICE_NAME ?? "sutro-studio2",
  otelEndpoint: import.meta.env.OTEL_EXPORTER_OTLP_ENDPOINT,
  intercomAppId: import.meta.env.INTERCOM_APP_ID,
  posthogKey: import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  posthogHost: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  stripePublicKey: import.meta.env.STRIPE_PUBLIC_KEY,
};

export default APP_CONFIG;
